import React from "react";
import { DashboardUsage, DashboardKeys } from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { CopyBlock, github } from "react-code-blocks";


function DashboardSection(props) {
  const auth = useAuth();

  const codeBlock = `name: "Pull Pilot PR Reviewer"

on: pull_request
permissions: write-all
jobs:
  pullpilot:
    runs-on: ubuntu-latest
    steps:
      - name: Pull Pilot Review
        id: pr_review
        uses: pullpilotai/pullpilot-pr-review@main
        with:
            token: \${{ secrets.GITHUB_TOKEN }}
            pull_pilot_token: \${{ secrets.PULL_PILOT_KEY }}`;

  return (
    <div
      id="home"
      className="relative pt-[120px] md:pt-[130px] lg:pt-[160px] bg-website text-white"
    >
      <div className="container">
        <section className="py-12">
          <div className="container mx-auto">
            <div className="flex flex-wrap">
              <div className="p-4 w-full md:w-1/2">
                <div className="rounded border border-gray-200 ">
                  <DashboardKeys />
                </div>
              </div>
              <div className="p-4 w-full md:w-1/2">
                <div className="rounded border border-gray-200 bg-light">
                  <DashboardUsage />
                </div>
              </div>
              <div className="p-4 w-full md:w-1/2">
                {auth.user.credits > 0 && (
                  <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none bg-website text-white">
                    <h2 className="mb-4 text-white">Let's get setup</h2>
                    <h3 className="mb-4 text-white">1. Create your API key</h3>
                    <p>
                      If you haven't created an API key yet, just click <em>Add API Key</em> above, and copy its value.
                    </p>
                    <h3 className="mb-4 text-white">2. Add a secret to your github</h3>
                    <p>
                      Once you've created your API Key, head on over to the Github Repository you'd like this
                      action to run on, and add the
                      <span className="bg-code mx-2 p-1 px-4 text-light rounded italic">PULL_PILOT_KEY</span> secret to your repository.
                    </p>
                    <p>
                      If you're not sure how to add secrets, have a look at <a className="text-purple-600" href={"https://docs.github.com/en/actions/security-guides/encrypted-secrets"}>this page</a>.
                    </p>
                    <p className="font-small italic">
                      <em>Note: Files mentioned in your <span className="bg-code p-1 px-2 text-light rounded italic">.gitignore</span> will not be reviewed.</em>
                    </p>

                    <h3 className="mb-4 text-white">3. Create your Github Action</h3>
                    <p>
                      In your Github repository, add the file <span className="bg-code mx-1 p-1 px-3 text-light rounded italic">.github/workflows/pull_pilot.yml</span> with the following content:
                    </p>
                    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

                    <CopyBlock
                      text={codeBlock}
                      language="Yaml"
                      showLineNumbers={false}
                      theme={github}
                      codeBlock={true}
                    />

                    <p>
                      That's it. Pull Requests will automatically be reviewed by Pull Pilot (As long as you have credits remaining).
                    </p>


                    <h3 className="mb-4 text-white">4. Extra Settings</h3>
                    <p>
                      There are extra parameters you can add to your action file.
                      <ul>
                        <li>
                          Change the amount of details in your reviews by adding/changing the <span className="bg-code p-1 px-1 text-light rounded">pull_pilot_mode</span> and the values can be "brief" or "full".
                          <ul>
                            <li><em>Brief</em> reviews the files briefly</li>
                            <li><em>Full</em> reviews each file and provides a summary, security implications and quick intellectual property review</li>
                          </ul>
                        </li>
                        <li>
                           The PR review process issues a job to the Pull Pilot API. After the PR has been sent off for review, the Github Action will
                          poll the API until the PR has been reviewed by Pull Pilot. <br /><br />
                          Using the <span className="bg-code p-1 px-1 text-light rounded">pull_pilot_retry</span>, you can change the amount
                          of seconds in between polling requests. For instance: <em>pull_pilot_retry: 60</em> will wait 60 seconds between each poll.
                        </li>
                      </ul>
                    </p>

                    <h3 className="mb-4 text-white">5. Buy more credits</h3>
                    <div>
                      <p>
                        You currently have ${Math.round(auth.user.credits)/100}.
                      </p>
                      <p>
                        You can buy more {" "}
                        <Link className="text-purple-600" to="/pricing">credits here</Link>.
                      </p>
                    </div>
                  </div>
                )}
                {auth.user.credits <= 0 && (
                  <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none bg-website">
                    <h2 className="mb-4 text-white">Load up the credits</h2>
                    <h3 className="mb-4 text-white">You need credits to continue</h3>
                    <p>
                      As soon as you <Link className="text-primary-button" to="/pricing">buy credits</Link> the instructions will appear here.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DashboardSection;
