import React from "react";
import {requireAuth, useAuth} from "./../util/auth";
import {usePaymentsByOwner} from "../util/db";

function Payments(props) {
  const auth = useAuth();

  const {
    data: payments,
    status: paymentsStatus,
    error: paymentsError,
  } = usePaymentsByOwner(auth.user.uid);

  const paymentsAreEmpty = !payments || payments.length === 0;

  return (
    <>
      {paymentsError && (
        <div className="mb-4 text-red-600">{paymentsError.message}</div>
      )}
      <div className="scroll-y max-h-[300px] overflow-y-scroll">
        <div className="flex justify-between items-center p-4 border-b border-gray-200 bg-gradient-to-r from-violet-500 to-fuchsia-500 h-[70px]">
          <span className="text-xl">Payments</span>
        </div>

        {(paymentsStatus === "loading" || paymentsAreEmpty) && (
          <div className="p-8">
            {paymentsStatus === "loading" && <>Loading...</>}

            {paymentsStatus !== "loading" && paymentsAreEmpty && (
              <>No API Key yet. Click to create a new API Key</>
            )}
          </div>
        )}

        {paymentsStatus !== "loading" && payments && payments.length > 0 && (
          <>
            {payments.map((key, index) => (
              <div
                className={
                  "flex p-4 border-b border-gray-200 text-xs"
                }
                key={index}
              >
                Bought ${key.stripePaymentAmount / 100} worth of credits on {key.createdAt.toDate().toLocaleDateString()} {' '}
                @ {key.createdAt.toDate().toLocaleTimeString()}{' '}
                <a className="underline text-primary ml-4" href={key.stripeReceiptUrl} target="_blank" rel="noreferrer">Download</a>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}


function SettingsBilling(props) {
  return (
    <div
      id="billing"
      className="relative pt-[120px] md:pt-[130px] lg:pt-[160px] bg-website"
    >
      <div className="container">
        <section className="py-12">
          <div className="container mx-auto">
            <div className="flex flex-wrap">
              <div className="p-4 w-full">
                <div className="rounded border border-gray-200 bg-website text-white">
                  <Payments />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default requireAuth(SettingsBilling);
