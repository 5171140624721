import React, { useState } from "react";
import EditKeyModal from "./EditKeyModal";
import { useAuth } from "./../util/auth";
import {useUsagesByOwner} from "./../util/db";
import { createKey, deleteKey, useKeysByOwner } from "./../util/db";
import clipboardIcon from "../assets/images/icons/clipboard.svg";
import checkIcon from "../assets/images/icons/check.svg";

function DashboardKeys(props) {
  const auth = useAuth();

  const {
    data: keys,
    status: keysStatus,
    error: keysError,
  } = useKeysByOwner(auth.user.uid);

  const [creatingKey, setCreatingKey] = useState(false);
  const [updatingKeyId,setUpdatingKeyId] = useState(false);
  const keysAreEmpty = !keys || keys.length === 0;

  const canUseKeys = auth.user.stripeCustomerId;

  const handleKey = () => {
    if (canUseKeys) {
      const key = crypto.randomUUID();
      createKey({owner: auth.user.uid, value: key});
    } else {
      alert("To add API keys, you need to upgrade to the pro or business plan.")
    }
  }

  return (
    <>
      {keysError && (
        <div className="mb-4 text-red-600">{keysError.message}</div>
      )}
      <div className="scroll-y max-h-[300px] overflow-y-scroll">
        <div className="flex justify-between items-center p-4 border-b border-gray-200 bg-gradient-to-r from-violet-500 to-fuchsia-500 h-[70px]">
          <span className="text-xl">API Keys</span>
          <button
            className="py-2 px-4 bg-secondary bg-opacity-80 rounded border-0 hover:bg-secondary hover:bg-opacity-100 focus:outline-none"
            onClick={() => handleKey()}
          >
            Add API Key
          </button>
        </div>

        {(keysStatus === "loading" || keysAreEmpty) && (
          <div className="p-8">
            {keysStatus === "loading" && <>Loading...</>}

            {keysStatus !== "loading" && keysAreEmpty && (
              <>No API Key yet. Click to create a new API Key</>
            )}
          </div>
        )}

        {keysStatus !== "loading" && keys && keys.length > 0 && (
          <>
            {keys.map((key, index) => (
              <div
                className={
                  "flex p-4 border-b border-gray-200 text-xs" +
                  (keys.featured ? " bg-gray-100" : "")
                }
                key={index}
              >
                {key.value}
                <div className="ml-auto space-x-2 flex">
                  <img
                    className="h-[18px] w-[18px] cursor-pointer"
                    src={clipboardIcon}
                    alt=""
                    aria-hidden="true"
                    onClick={(e) => {
                      navigator.clipboard.writeText(key.value);
                      const target = e.currentTarget;
                      target.src = checkIcon;
                      setInterval(() => target.src = clipboardIcon , 1000);
                    }}
                  />
                  <div className="px-2">&nbsp;|&nbsp;</div>
                  <button
                    className="text text-purple-600"
                    onClick={() => deleteKey(key.id)}
                  >
                    Delete this key
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      {creatingKey && <EditKeyModal onDone={() => setCreatingKey(false)} />}
      {updatingKeyId && (
        <EditKeyModal
          id={updatingKeyId}
          onDone={() => setUpdatingKeyId(null)}
        />
      )}
    </>
  );
}


function DashboardUsage(props) {
  const auth = useAuth();

  const {
    data: usages,
    status: usagesStatus,
    error: usagesError,
  } = useUsagesByOwner(auth.user.uid);

  const usagesAreEmpty = !usages || usages.length === 0;

  return (
    <>
      {usagesError && (
        <div className="mb-4 text-red-600">{usagesError.message}</div>
      )}

      <div className="scroll-y max-h-[300px] overflow-y-scroll bg-website">
        <div className="flex justify-between usages-center p-4 border-b border-gray-200 bg-gradient-to-r from-violet-500 to-fuchsia-500 h-[70px]">
          <span className="text-xl">Usages (${Math.round(auth.user.credits)/100} Credits)</span>
        </div>

        {(usagesStatus === "loading" || usagesAreEmpty) && (
          <div className="p-8">
            {usagesStatus === "loading" && <>Loading...</>}

            {usagesStatus !== "loading" && usagesAreEmpty && (
              <>No usage associated with your account or api keys yet.</>
            )}
          </div>
        )}

        {usagesStatus !== "loading" && usages && usages.length > 0 && (
          <>
            {usages.map((usage, index) => (
              <div
                className={
                  "flex p-4 border-b border-gray-200"
                }
                key={index}
              >
                ${usage.amount}, Repo: {usage.repo}, PR: {usage.pr_number}, Tokens: {usage.tokens}
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export {
  DashboardUsage,
  DashboardKeys
};