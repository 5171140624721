import React from "react";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

import pullPilotLogoWhite from "./../assets/images/logo/pull-pilot-logo.png";

function Navbar(props) {
  const auth = useAuth();

  return (
    <div
      className="
        ud-header
        bg-transparent
        absolute
        top-0
        left-0
        z-40
        w-full
        flex
        items-center
        shadow-2xl
      "
    >
      <div className="container">
        <div className="flex -mx-4 items-center justify-between relative">
          <div className="px-4 w-60 max-w-full">
            <a href="/" className="navbar-logo w-full block py-5">
              <img
                src={pullPilotLogoWhite}
                alt="logo"
                className="w-full header-logo"
              />
            </a>
          </div>
          <div className="flex px-4 justify-between items-center w-full">
            <div>
              <button
                id="navbarToggler"
                className="
                  block
                  absolute
                  right-4
                  top-1/2
                  -translate-y-1/2
                  lg:hidden
                  focus:ring-2
                  ring-primary
                  px-3
                  py-[6px]
                  rounded-lg
                "
              >
                <span
                  className="relative w-[30px] h-[2px] my-[6px] block bg-white"
                ></span>
                <span
                  className="relative w-[30px] h-[2px] my-[6px] block bg-white"
                ></span>
                <span
                  className="relative w-[30px] h-[2px] my-[6px] block bg-white"
                ></span>
              </button>
              <nav
                id="navbarCollapse"
                className="
                  absolute
                  py-5
                  lg:py-0 lg:px-4
                  xl:px-6
                  bg-white
                  lg:bg-transparent
                  shadow-lg
                  rounded-lg
                  max-w-[250px]
                  w-full
                  lg:max-w-full lg:w-full
                  right-4
                  top-full
                  hidden
                  lg:block lg:static lg:shadow-none
                "
              >
                {!auth.user && (
                  <ul className="block lg:flex">
                    <li className="relative group">
                      <a
                        href="/dashboard"
                        className="
                        ud-menu-scroll
                        text-base text-dark
                        lg:text-white
                        lg:group-hover:opacity-70
                        lg:group-hover:text-white
                        group-hover:text-primary
                        py-2
                        lg:py-6 lg:inline-flex lg:px-0
                        flex
                        mx-8
                        lg:mr-0
                      "
                      >
                        Home
                      </a>
                    </li>
                    <li className="relative group">
                      <Link
                        to="/about"
                        className="
                        ud-menu-scroll
                        text-base text-dark
                        lg:text-white
                        lg:group-hover:opacity-70
                        lg:group-hover:text-white
                        group-hover:text-primary
                        py-2
                        lg:py-6 lg:inline-flex lg:px-0
                        flex
                        mx-8
                        lg:mr-0 lg:ml-7
                        xl:ml-12
                      "
                      >
                        About
                      </Link>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
            <div className="sm:flex justify-end hidden pr-16 lg:pr-0">
              {!auth.user && (
                <>
                  <Link
                    to="/auth/signin"
                    className="
                  text-base
                  font-medium
                  text-white
                  hover:opacity-70
                  py-3
                  px-7
                  loginBtn
                "
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/auth/signup"
                    className="
                  text-base
                  font-medium
                  text-white
                  bg-white bg-opacity-20
                  rounded-lg
                  py-3
                  px-6
                  hover:bg-opacity-100 hover:text-dark
                  signUpBtn
                  duration-300
                  ease-in-out
                "
                  >
                    Sign Up
                  </Link>
                </>
              )}
              {auth.user && (
                <>
                  <Link
                    to="/dashboard"
                    className="
                      text-base
                      font-medium
                      text-white
                      hover:opacity-70
                      py-3
                      px-7
                      loginBtn
                    "
                  >
                    Dashboard
                  </Link>
                  {auth.user.credits / 100 > 0 && (
                    <Link
                      to="/settings/billing"
                      className="
                      text-base
                      font-medium
                      text-white
                      hover:opacity-70
                      py-3
                      px-7
                      loginBtn
                    "
                    >
                      Payments
                    </Link>
                  )}
                  <Link
                    to="/auth/signout"
                    className="
                      text-base
                      font-medium
                      text-white
                      hover:opacity-70
                      py-3
                      px-7
                      loginBtn
                    "
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign Out
                  </Link>
                  <Link
                    to="/pricing"
                    className="
                text-base
                  font-medium
                  text-white
                  bg-primary-button bg-opacity-80
                  rounded-lg
                  py-3
                  px-6
                  hover:bg-opacity-100 hover:text-white
                  signUpBtn
                  duration-300
                  ease-in-out
                "

                  >
                    Buy Credits 💰
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
