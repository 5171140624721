import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import { requireAuth } from "../util/auth";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection />
    </>
  );
}

export default requireAuth(PricingPage);
