import React from "react";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

function PricingSection(props) {
  const auth = useAuth();

  const plans = [
    {
      id: "small",
      name: "Small Chunks",
      price: "5",
      perks: [
        " 22,000 Tokens",
        "or",
        " 55,000 characters",
        "Counts are approximate"
      ],
    },
    {
      id: "medium",
      name: "Medium Chunks",
      price: "20",
      perks: [
        " 90,000 Tokens",
        "or",
        " 225,000 characters",
        "Counts are approximate"
      ],
    },
    {
      id: "larger",
      name: "larger",
      price: "100",
      perks: [
        " 450,000 Tokens",
        "or",
        " 1,125,000 characters",
        "Counts are approximate"
      ],
    },
  ];

  return (
    <div
      id="pricing"
      className="relative py-12 md:pt-[130px] lg:pt-[160px] bg-website text-white"
    >
      <div className="container mx-auto max-w-6xl">
        <h1 className="mb-6 text-3xl font-medium text-center">
          Pick what suits
        </h1>
        <p className="font-medium text-center">
          Here's how it works. We use GPT3 (The models behind ChatGPT). GPT3's Davinci model (which we use) costs $0.020 per 1000 tokens
          <span className="hidden sm:block">(~2000-3000 characters)</span>.
        </p>
        <p className="font-medium text-center">
          We're
          adding about 10% on top of that to cover server costs and tools.
        </p>
        <div className="flex flex-wrap">
          {plans.map((plan, index) => (
            <div className="p-4 w-full md:w-1/3" key={index}>
              <div className="flex flex-col p-5 h-full rounded border border-gray-300">
                <div className="text-xl">
                  {plan.name} (${plan.price}/mo)
                </div>

                {plan.perks && (
                  <ul className="mt-6 mb-6">
                    {plan.perks.map((perk, index) => (
                      <li className="mb-1" key={index}>
                        → {perk}
                      </li>
                    ))}
                  </ul>
                )}

                <Link
                  to={
                    auth.user
                      ? `/purchase/${plan.id}`
                      : `/auth/signup?next=/purchase/${plan.id}`
                  }
                  className="py-3 px-5 mt-auto w-full text-white bg-blue-500 rounded border-0 hover:bg-blue-600 focus:outline-none"
                >
                  Choose
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PricingSection;
